import React, { useState, useEffect } from "react";
import "./about.css";
import portrait from "../assets/portrait.jpeg";

export default function About() {
    const [isVisible, setIsVisible] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        // Set timeouts for fading effects only if image is loaded
        if (imageLoaded) {
            const timeoutId = setTimeout(() => {
                setIsVisible(true);
            }, 600);

            const timeoutId2 = setTimeout(() => {
                setIsVisible2(true);
            }, 200);
  
            // Clean up timeouts
            return () => {
                clearTimeout(timeoutId);
                clearTimeout(timeoutId2);
            };
        }
    }, [imageLoaded]);  // Dependency on imageLoaded state ensures timeouts are reset when image loads

    return (
        <div className='container'>
            <div className={`frontdiv ${isVisible ? 'fade-in' : ''}`}>
                <div className="abouttxt">
                    <p className="title">About Me</p>
                    <p className="txt">I'm a Full Stack Engineer specializing in Node.js and React. My journey in web development has led me to excel in both backend API development and crafting polished front-end interfaces. <br/><br/>I'm passionate about staying at the forefront of technology trends and leveraging cutting-edge solutions to create innovative web applications. <br/><br/>Beyond coding, I'm an eager learner, always seeking fresh opportunities to expand my skill set. Let's connect and explore how I can contribute to your next project.</p>
                </div>
                {/* Ensure image has onLoad and onError handlers */}
                <img 
                    className="portrait" 
                    alt="portrait" 
                    src={portrait} 
                    onLoad={() => setImageLoaded(true)}
                    onError={() => setImageLoaded(false)}
                    style={{ display: imageLoaded ? "block" : "none" }}  // Hide image until it's loaded
                />
            </div>
            <div className={`backdiv ${isVisible2 ? 'fade-in' : ''}`} />
        </div>
    );
}
