import React, { useState, useEffect } from "react";
import home from "../assets/home.jpg";
import "./portfolio.css";
import vibecue from "../assets/vibecue.png";
import doorcast from "../assets/Doorcast.png";
import squeezed from "../assets/squeezed.png";
import denotes from "../assets/ComingSoon.png";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function Portfolio() {
    const [isVisible, setIsVisible] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    useEffect(() => {
        // Set timeouts for fading effects only if image is loaded
        if (imageLoaded) {
            const timeoutId = setTimeout(() => {
                setIsVisible(true);
            }, 200);
  
            // Clean up timeouts
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [imageLoaded]);
    // useEffect(() => {
    //     // Preload images
    //     const preloadImages = [home, vibecue, doorcast, squeezed, denotes];  // Ensure all used images are preloaded
    //     preloadImages.forEach(image => {
    //         const link = document.createElement('link');
    //         link.rel = 'preload';
    //         link.as = 'image';
    //         link.href = image;
    //         document.head.appendChild(link);
    //     });

    //     // Trigger the fade-in effect after a delay
    //     const delay = 200;
    //     const timeoutId = setTimeout(() => {
    //         setIsVisible(true);
    //     }, delay);
  
    //     // Clean up
    //     return () => {
    //         clearTimeout(timeoutId);
    //         preloadImages.forEach(image => {
    //             // Remove preload links to clean up head and avoid pollution
    //             const links = document.querySelectorAll(`link[rel="preload"][href="${image}"]`);
    //             links.forEach(link => document.head.removeChild(link));
    //         });
    //     };
    // }, []);

    return (
        <div className="portfolio">
            <div className="header">
                <img 
                    className={`fading-image ${isVisible ? 'fade-in' : ''}`} 
                    alt="home" src={home}
                    onLoad={() => setImageLoaded(true)}
                    onError={() => setImageLoaded(false)}
                    style={{ display: imageLoaded ? "block" : "none" }} 
                />
            </div>
            <div className={`projects ${isVisible ? 'fade-in' : ''}`}>
                <p className="intro">Here are some of my most recent projects</p>
                <div className="box">
                    <div className="projectTxt">
                        <p className="projectTittle">Trapnstudio</p>
                        <p className="description">
                            As part of my internship at Trapnstudio, I have been working on <a href="https://www.vibecue.app" style={{ textDecoration: 'none', color: 'inherit' }}><strong>Vibecue</strong></a>, an application designed to enhance social interaction and user engagement through innovative features. 
                            Trapnstudio focuses on redefining how music is experienced by marrying music with technology, aiming to be a global leader in creating state-of-the-art solutions for DJs, venues, artists, and fans. Vibecue is one of their key products, reflecting their vision to foster spontaneous celebrations of sound, culture, and connection around the world.
                            <br/><br/>
                            In my role on the Vibecue project, I have engineered key widgets for user profile management, including integrating social media links and payment methods, and implemented a base64 encoding/decoding feature to facilitate URL creation for transactions. I also optimized code modularity to improve the app's maintainability and scalability, and enhanced its global reach by implementing multi-language support using Flutter's i18n, making Vibecue accessible to a wider audience.
                        </p>
                        <a className="atag" href="https://www.trapnstudio.com/trapnstudio" target="_blank">
                            <p className="link">Learn More</p>
                            <KeyboardArrowRightIcon className="arrow"/>
                        </a>
                    </div>
                    <img className="projectimg" alt="vibecue" src={vibecue} />
                </div>
                <div className="box">
                    <div className="projectTxt">
                        <p className="projectTittle">Squeezed</p>
                        <p className="description">
                            Squeezed is a prototyping tool that allows developers to render code in the new fullstack framework called Fresh. Simply by selecting the element you want to render, dropping it into the workspace, and specifying the necessary attribute values, users then have the ability to copy and utilize production-ready code for their application.
                        </p>
                        <a className="atag" href="https://medium.com/@alexaroberts/prototyping-tool-for-the-freshest-deno-framework-3df9c4c718dc" target="_blank">
                            <p className="link">Learn More</p>
                            <KeyboardArrowRightIcon className="arrow"/>
                        </a>
                    </div>
                    <img className="projectimg1" alt="squeezed" src={squeezed} />
                </div>
                <div className="box">
                    <div className="projectTxt">
                        <p className="projectTittle">DoorCast</p>
                        <p className="description">
                            At Doorcast, a multi-family, real estate investment company, I developed a full stack application that automated a number of internal processes including due diligence, renovation, maintenance, and personnel/resource management. The technology stack for this project included cutting-edge tools and frameworks like React for building the user interface, Redux for state management, Storybook for component documentation and testing, Next.js for server-side rendering, and Atlassian tools for project management and collaboration.
                        </p>
                    </div>
                    <img className="projectimg" alt="doorcast" src={doorcast} />
                </div>
                <div className="box">
                    <div className="projectTxt">
                        <p className="projectTittle">Denotes</p>
                        <p className="description">
                            Experience Denotes, a cutting-edge note-taking web application built using Fresh, the new full stack framework developed in Deno. With a lightweight, responsive design, it's the perfect platform for users to create accounts, compose and securely store their notes, and effortlessly share them with others. Leveraging innovative server-side rendering, Denotes ensures fast load times and exceptional performance. Seamlessly synchronize your notes across devices, and access them from anywhere, anytime.
                        </p>
                    </div>
                    <img className="projectimg" alt="denotes" src={denotes} />
                </div>
            </div>
        </div>
    );
}




